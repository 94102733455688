import React from 'react'

import './notFound.css'

class NotFound extends React.Component {

    render() {
        return(
            <div className="notFound">
                <h2>Error 404</h2>
                <p>Sorry The Page You</p>
                <p>Are Looking For</p>
                <p>Is Not Found</p>
            </div>
        )
    }
}

export default NotFound